<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
      >
        <slot></slot>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { PullRefresh } from 'vant'
export default {
  name: "PullRefreshList",
  components: {
    [PullRefresh.name]: PullRefresh
  },
  data() {
    return {
      error: false,
      loading: false,
      finished: false,
      refreshing: false,
      page: 0,
      total: 0
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    search: {
      type: Object | Function,
      default: () => {
      }
    },
    getPageData: {
      type: Function,
      default: () => {
      }
    },
    pageSize: {
      type: Number,
      default: 10
    },
  },
  methods: {
    onLoad() {
      let params = this.search;
      params = params || {};
      this.getPageData({
        ...params,
        page: this.page + 1 + '',
        pageSize: this.pageSize + ''
      }).then(res => {
        if (this.refreshing) {
          // this.list = []; // 这里会报错，props list
          this.list.splice(0, this.list.length);
          this.refreshing = false;
        }

        if (res.results.items && res.results.items.length) {
          this.list.push(...res.results.items);
        }

        this.total = res.total;
        this.loading = false;
        this.page++;
        if (res.results.items.length === 0 || this.list.length >= res.total) {
          this.finished = true;
        }
      }).catch(() => {
        this.error = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.page = 0;
      this.onLoad();
    },
    reloadList() {
      // 清空列表数据
      this.list.splice(0, this.list.length);
      this.refreshing = true;
      this.onRefresh();
    },
  }
}
</script>

<style lang="less" scoped>

</style>